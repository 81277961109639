import * as React from "react"
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Footer from '../components/Footer'
// @ts-ignore
import patrick from "../img/PatrickOlsen.jpg"
// @ts-ignore
import peter from "../img/PeterBlochJohansen.jpg"
// @ts-ignore
import linkedin from "../img/linkedin-logo.png"

export default () => {
  return (
    <main className="whopage">
      <Helmet>
        <title>Who we are - MOP Floorcheck</title>
        <link rel="canonical" href="https://mopfloorcheck.com/who-we-are" />
      </Helmet>
      <Header tab="who" />
      <section className="intro">
        <div className="intro-content">
          <h2>THIS IS MOP FLOORCHECK</h2>
          <hr />
          <p>We handle stock audits of assets</p>
        </div>
      </section>
      <section className="infobox-section">
        <div className="container">
          <div className="infobox">
            <h3>Michael Olsen & Partnere A/S</h3>
            <p>Michael Olsen & Partnere A/S was founded in 1987 by the present major shareholder Michael Olsen. From the beginning, the company specialised in debt collection on cars, with clients being among the biggest financial institutions and banks in Denmark.</p>
            <p>Business expanded over the years as clients requirements changed, and different services developed. Today, the company owns a number of subsidiaries working with areas like debt collection, legal advice, and stock auditing.</p>
            <p>The main office is located in Copenhagen where management and administration is situated.</p>
            <p>MOP currently provides services in Denmark, Sweden, Norway, and Finland.</p>
            <h3>MOP Floorcheck</h3>
            <p>Since 1998, we have specialised in stock audits, handling thousands of dealerships in the Nordic countries for prominent car importers and financial institutions.</p>
            <p>Audits are monitored and planned from our office in Copenhagen, which also handles day-to-day contact with our clients as well as the requested reporting.</p>
            <p>We handle stock audits on cars, trucks, caravans, motorcycles, boats, and industrial machinery, but we are open to new challenges too!</p>
          </div>
        </div>
      </section>
      <section className="management">
        <div className="container">
          <h2>MANAGEMENT</h2>
          <hr />
          <div className="row">
            <div className="col-md">
              <img className="profile" src={patrick} />
              <h3>PATRICK OLSEN</h3>
              <h5>CEO</h5>
              <p>Patrick holds a diploma in financing from Copenhagen Business School. He has a background with Nordea Bank Danmark A/S.</p>
              <a href="https://www.linkedin.com/in/patrick-allan-olsen-98b70453/"><img className="linkedin" src={linkedin} /></a>
            </div>
            <div className="col-md">
              <img className="profile" src={peter} />
              <h3>PETER BLOCH JOHANSEN</h3>
              <h5>FLOORCHECK MANAGER</h5>
              <p>Peter was appointed Floorcheck Manager at MOP beginning 2018. He is our guarantor of high quality stock audits. Before joining MOP, Peter was working with some of the largest media houses in Denmark.</p>
              <a href="https://www.linkedin.com/in/peter-bloch-johansen-7b153a7/"><img className="linkedin" src={linkedin} /></a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  )
}
